<template>
	<div class="py-5">
		<div class="hstack justify-content-center text-secondary">
			<span class="h1 my-0" style="font-size: 60px;">404</span>
			<div class="vr mx-2" style="width: 5px;"></div>
			<span class="h1 my-0" style="font-size: 30px;">Halaman Tidak Ditemukan.</span>
		</div>
	</div>
</template>

<style scoped></style>

<script>
export default {
	name: 'NotFound'
}
</script>