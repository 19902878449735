<template>
  <main class="container text-center my-3">
    <img class="mb-2" src="./assets/logo.png" alt="" width="150">
    <h3 class="">Single Sign-On</h3>
    <h5 class="mb-0">Aplikasi Berbasis Web</h5>
    <h5>SMK PGRI 1 KEDIRI</h5>
    <router-view />
  </main>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
    }
  },
  created() {
  },
  methods: {}
}
</script>

<style></style>
