<template>
  <div class="">
    <div class="form-signin">
      <form action="#" method="post" @submit.prevent="login" ref="formLogin">
        <div class="form-floating">
          <input type="text" v-model="form.username" class="form-control form-control-sm" id="floatingUsername"
            placeholder="Nama Pengguna" required autofocus
            oninvalid="this.setCustomValidity('Silahkan masukkan username.')" oninput="this.setCustomValidity('')">
          <label for="floatingUsername">Nama Pengguna</label>
        </div>
        <div class="form-floating">
          <input type="password" v-model="form.password" class="form-control form-control-sm" id="floatingPassword"
            placeholder="Kata Sandi" required oninvalid="this.setCustomValidity('Silahkan masukkan kata sandi.')"
            oninput="this.setCustomValidity('')">
          <label for="floatingPassword">Kata Sandi</label>
        </div>
        <button class="w-100 btn btn-lg btn-primary mt-2" type="submit">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
            class="bi bi-box-arrow-in-right" viewBox="0 0 20 20">
            <path fill-rule="evenodd"
              d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
            <path fill-rule="evenodd"
              d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
          </svg>
          Masuk</button>
      </form>
      <hr />
      <google-login :callback="callbackGoogle" prompt />
      <p class="mt-5 text-muted">&copy; 2023</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoginForm',
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
    };
  },
  components: {
  },
  created() {
  },
  methods: {
    async login() {
      let memuat = this.$loading.show({
        container: this.$refs.formLogin,
        loader: 'bars'
      });
      try {
        const response = await this.axios.post('/login', this.form);
        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          this.callback();
          this.$parent.loggedIn = true
        }
      } catch (err) {
        if (err.response) {
          this.$swal({
            title: 'Gagal',
            text: err.response.data.error,
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.form.password = '';
          });
        } else {
          console.log(err);
          this.$swal({
            title: 'Gagal',
            text: err.message,
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async callback() {
      const headers = {
        Authorization: localStorage.getItem('token')
      }
      try {
        const response = await this.axios.post('/info', {}, {
          headers: headers
        });
        if (response.data.user) {
          this.$parent.user = response.data.user
          this.getRedirectApp();
        }
      } catch (err) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        console.log(err);
        setTimeout(() => {
          this.$swal({
            title: 'Gagal',
            text: err.response.data.error,
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          })
        }, 250);
      }
    },
    async getRedirectApp() {
      try {
        const response = await this.axios.post('/app', {
          aplikasi_id: this.$route.params.id
        });
        if (response.data.aplikasi) {
          this.$parent.aplikasi = response.data.aplikasi
          this.$parent.url_auth = response.data.aplikasi.url + "/auth/" + localStorage.getItem('token');
          window.location.href = response.data.aplikasi.url + "/auth/" + localStorage.getItem('token');
        }
      } catch (err) {
        console.log(err);
      }
    },
    async callbackGoogle(responseGoogle) {
      let memuat = this.$loading.show({
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/verify-credential', {
          credential: responseGoogle.credential
        }, {
        });
        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          this.callback();
          this.$parent.loggedIn = true;
        }
      } catch (err) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        console.log(err);
        this.$swal({
          title: 'Gagal',
          text: err.response.data.error,
          icon: 'error',
          showConfirmButton: false,
          timer: 1500
        })
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    }
  }
}
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
}
</style>