<template>
  <div>
    <div v-if="loggedIn">
      <div v-if="user" class="card my-4 mx-auto shadow" style="max-width: 540px;">
        <div class="card-header">
          <h5 class="card-title my-0">Anda masuk sebagai</h5>
        </div>
        <div class="row g-0">
          <div class="col-sm-4 p-sm-2 p-4">
            <img v-if="user.foto" :src="user.foto" class="img-fluid rounded-start" alt="">
            <img v-else src="../assets/person.png" class="img-fluid rounded-start" alt="">
          </div>
          <div class="col-sm-8 d-flex">
            <div class="card-body my-auto">
              <table class="table table-sm table-borderless">
                <tbody class="text-start small">
                  <tr>
                    <td>Nama</td>
                    <td class="text-end">:</td>
                    <th>{{ user.nama }}</th>
                  </tr>
                  <tr>
                    <td>Peran</td>
                    <td class="text-end">:</td>
                    <th>{{ user.role }}</th>
                  </tr>
                  <tr>
                    <td>Keterangan</td>
                    <td class="text-end">:</td>
                    <th>{{ user.ket }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-footer py-1">
          <button class="btn btn-sm btn-outline-danger px-3" @click="logout">Logout</button>
        </div>
      </div>
      <div v-if="aplikasi" class="fst-italic">
        Anda akan dialihkan ke laman <a :href="url_auth">{{ aplikasi.nama }}</a>
      </div>
      <div v-else class="text-secondary">
        <h6>Aplikasi Tidak Ditemukan</h6>
      </div>
    </div>
    <login-form v-else />
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue';
export default {
  components: { LoginForm },
  name: 'HomeView',
  data() {
    return {
      user: {},
      aplikasi: null,
      loggedIn: true,
      url_auth: null
    }
  },
  created() {
    if (localStorage.getItem('token')) {
      this.callback();
    } else {
      this.loggedIn = false
    }
  },
  methods: {
    async callback() {
      const headers = {
        Authorization: localStorage.getItem('token')
      }
      try {
        const response = await this.axios.post('/info', {}, {
          headers: headers
        });
        if (response.data.user) {
          this.user = response.data.user;
          this.getRedirectApp();
        }
      } catch (err) {
        localStorage.removeItem('token');
        this.user = null;
        this.aplikasi = null;
        this.loggedIn = false;
      }
    },
    async getRedirectApp() {
      try {
        const response = await this.axios.post('/app', {
          aplikasi_id: this.$route.params.id
        });
        if (response.data.aplikasi) {
          this.aplikasi = response.data.aplikasi
          this.url_auth = response.data.aplikasi.url + "/auth/" + localStorage.getItem('token');
          window.location.href = response.data.aplikasi.url + "/auth/" + localStorage.getItem('token');
        }
      } catch (err) {
        console.log(err);
      }
    },
    async logout() {
      const headers = {
        Authorization: localStorage.getItem('token')
      }
      try {
        const response = await this.axios.post('/logout', {}, {
          headers: headers
        });
        if (response) {
          localStorage.removeItem('token');
          this.user = null;
          this.aplikasi = null;
          this.loggedIn = false;
        }
      } catch (err) {
        localStorage.removeItem('token');
        this.user = null;
        this.aplikasi = null;
        this.loggedIn = false;
      }
    }
  }
}
</script>
