import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAxios from "vue-axios";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import vue3GoogleLogin from "vue3-google-login";

const app = createApp(App);

// axios.defaults.baseURL = "http://auth.com:3000";
// app.config.globalProperties.$appId = "5e7f23d3-10cd-11ef-801e-ac120340b234";

// axios.defaults.baseURL = "http://auth.local";
// app.config.globalProperties.$appId = "f4c40df7-271d-11ef-abda-2b8d42a26824";

axios.defaults.baseURL = "https://localauth.sitepgri.com";
app.config.globalProperties.$appId = "0ed289ac-3a98-11ee-a779-ac120340b234";

axios.defaults.withCredentials = true;

app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(LoadingPlugin, {
    canCancel: false,
    color: "#002b80",
    backgroundColor: "#ccddff",
    opacity: 0.4,
    zIndex: 9999,
});
app.use(vue3GoogleLogin, {
    clientId:
        "821702651862-33trhluu53coufb20hm1g8ijku4na425.apps.googleusercontent.com",
});

app.mount("#app");
